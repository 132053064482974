@media print {
  @page {
    margin: 1cm !important;
    size: a4 !important;
  }

  body {
    &.app-body {
      min-width: 900px !important;
      overflow: hidden !important;
    }
  }

  .media-print {
    &#{&} {
      visibility: visible;
    }
  }

  .media-screen {
    &#{&} {
      visibility: hidden;
    }
  }

  .scrollable-container {
    &#{&} {
      height: auto !important;
      max-width: 100%;
      min-width: 100%;
      position: relative !important;
      top: 0 !important;
      width: 100% !important;
    }
  }

  .page-container {
    &#{&} {
      min-height: auto !important;
    }
  }

  // MUI specific - start
  .MuiContainer-root {
    &#{&} {
      max-width: 100%;
    }
  }
  // MUI specific - end
}

@media screen {
  .media-print {
    &#{&} {
      visibility: hidden;
    }
  }

  .media-screen {
    &#{&} {
      visibility: visible;
    }
  }
}
