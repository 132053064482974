@import 'print';
@import 'typography';
@import 'colors';
@import 'animations';
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;500;600;700&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'embla';

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(assets/material-icons-outlined.woff2) format('woff2');
}

//GLOBALS
@import './assets/styles/global/mat-slider.scss';
@import './assets/styles/global/spacing.scss';

* {
  font-family: 'Assistant', sans-serif;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

body {
  background-color: $edupass-ice;
  margin: 0;
  height: 100vh;
}

.bg-ice {
  background-color: $edupass-ice;
}

.bg-white {
  background-color: $edupass-white;
}

.bg-white {
  background-color: $edupass-white;
}

.text-white {
  color: $edupass-white;
}

.edupass-purple {
  color: $edupass-purple;
}

.page-expand {
  min-height: calc(100vh - #{$topbar-height} - #{$lg-footer-height});
}

.text-decoration-line-through {
  text-decoration: line-through;
}

#com-purple-metrics.hide {
  display: none !important;
}

//Não apagar implementar parar cores com classe Falar com o Matheus
// @each $name, $color in $colors {
//   .color-#{$name} {
//     color: $color;
//   }
// }
